/** @format */

import React from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import MyNavbar from "./MyNavbar";
import { Row, Col, Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta name="icon" href="../images/chapin_icon.png" />
      </Helmet>
      <MyNavbar />
      <Container fluid>
        <Row>
          <Col lg={1} className="order-last order-lg-first mt-5">
            <div className="d-flex flex-row flex-lg-column justify-content-center">
              <StaticImage src="../images/barras-footer.png" alt="barras" className="imagen-barras-redes" />

              <a
                href="https://www.facebook.com/Chapinsingluten/"
                target="_blank"
                rel="noreferrer"
                className="px-2 mt-lg-3 text-reset align-self-center"
              >
                <StaticImage src="../images/icon-facebook.png" alt="Sitio facebook" width={15} className="mb-2" />
              </a>
              <a
                href="https://instagram.com/chapinsingluten"
                target="_blank"
                rel="noreferrer"
                className="px-2 text-reset align-self-center"
              >
                <StaticImage src="../images/icon-instagram.png" alt="Sitio instagram" width={18} className="mb-2" />
              </a>
              <a
                href="https://wa.me/50230368772"
                target="_blank"
                rel="noreferrer"
                className="px-2 text-reset align-self-center"
              >
                <StaticImage src="../images/icon-whatsapp.png" alt="Whatsapp" width={18} className="mb-2" />
              </a>
            </div>
          </Col>
          <Col>
            <Container>{children}</Container>
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
