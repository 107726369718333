/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { AppContext } from "../utils/ContextWrapper";

const activeNavStyles = {
  width: 70,
  height: 3,
  backgroundColor: "orange",
  marginTop: 5,
};

export default function MyNavbar() {
  const [currentLocation, setCurrentLocation] = useState("");

  const isActive = ({ location }) => {
    setCurrentLocation(location.pathname);
  };

  // administra el estado de la aplicacion
  const context = useContext(AppContext);

  const cerrarSesion = () => {
    context.actions.cerrarSesion();
    //localStorage.setItem("session_chapin", context.store);
  };
  useEffect(() => {
    //console.log(localStorage);
    /* if (localStorage.hasOwnProperty("session_chapin")) {
      context.actions.setSession(JSON.parse(localStorage.getItem("session_chapin")));
    }*/
  }, []);

  return (
    <Navbar collapseOnSelect expand="lg" sticky="top" bg="white" variant="light">
      <Container>
        <Link className="navbar-brand" to="/">
          <StaticImage src="../images/logo.png" alt="Logo" width={100} />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="estilo-texto-navbar">
          <Nav className="me-auto">
            <Link className="nav-link" to="/sobre-nosotros/" getProps={isActive}>
              <p className={`m-0 ${currentLocation === "/sobre-nosotros/" ? "nav-seleccionado" : ""}`}>
                Sobre nosotros
              </p>
            </Link>
            <Link className="nav-link" to="/productos/" getProps={isActive}>
              <p className={`m-0 ${currentLocation === "/productos/" ? "nav-seleccionado" : ""}`}>Nuestros productos</p>
            </Link>
            <Link className="nav-link" getProps={isActive} to="/cerca-de-mi/">
              <p className={`m-0 ${currentLocation === "/cerca-de-mi/" ? "nav-seleccionado" : ""}`}>Cerca de mí</p>
            </Link>
            <Link className="nav-link" getProps={isActive} to="/recetas/">
              <p className={`m-0 ${currentLocation === "/recetas/" ? "nav-seleccionado" : ""}`}>Recetas</p>
            </Link>
          </Nav>
          <Nav>
            <Link className="nav-link" getProps={isActive} to="/comprar/">
              <p className={`m-0 ${currentLocation === "/comprar/" ? "nav-seleccionado" : ""}`}>
                Comprar <StaticImage src="../images/shopping_car.png" alt="Shoping car image" width={15} /> (
                {context.actions.obtenerTotalProductos(context.store.carrito)})
              </p>
            </Link>

            <NavDropdown
              title={
                context.store.usuarioAutenticado.nombre?.length === 0
                  ? "Login"
                  : context.store.usuarioAutenticado.nombre
              }
              id="seleccion-login"
            >
              {context.store.usuarioAutenticado.nombre?.length === 0 && (
                <>
                  <NavDropdown.Item>
                    <Link className="nav-link" getProps={isActive} to="/registro/">
                      <p className={`m-0 ${currentLocation === "/registro/" ? "nav-seleccionado" : ""}`}>
                        Nueva Cuenta
                      </p>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link className="nav-link" getProps={isActive} to="/login/">
                      <p className={`m-0 ${currentLocation === "/login/" ? "nav-seleccionado" : ""}`}>Iniciar sesión</p>
                    </Link>
                  </NavDropdown.Item>
                </>
              )}

              {context.store.usuarioAutenticado.nombre?.length > 0 && (
                <>
                  <NavDropdown.Item>
                    <Link className="nav-link" getProps={isActive} to="/perfil/">
                      <p className={`m-0 ${currentLocation === "/perfil/" ? "nav-seleccionado" : ""}`}>Mi perfil</p>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link className="nav-link" getProps={isActive} to="/reset-password/">
                      <p className={`m-0 ${currentLocation === "/reset-password/" ? "nav-seleccionado" : ""}`}>
                        Cambiar contraseña
                      </p>
                    </Link>
                  </NavDropdown.Item>

                  <NavDropdown.Item>
                    <Link onClick={cerrarSesion} className="nav-link" getProps={isActive} to="/">
                      <p className="m-0">Cerrar sesion</p>
                    </Link>
                  </NavDropdown.Item>
                </>
              )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
